// Initial state
const initial_state = {
    cashierUpdater: 0,
    adminUpdater:0,
    embryologyUpdater:0,
    showPatientCard:false,
    receptionUpdater:0
}





// Reducer
const applicationReducer = (state = initial_state, action) => {
    switch (action.type) {
        case 'SET_UPDATER':
            return {
                ...state,
                cashierUpdater: state.cashierUpdater + 1
            };

        case 'SET_ADMIN_UPDATER':
            return {
                ...state,
                adminUpdater: state.adminUpdater + 1
            };

        case 'SET_EMBRYOLOGY_UPDATER':
            return {
                ...state,
                embryologyUpdater: state.embryologyUpdater + 1
            };


        case 'SHOW_PATIENT_CARD':
            return {
                ...state,
                showPatientCard: true
            };


        case 'HIDE_PATIENT_CARD':
            return {
                ...state,
                showPatientCard: false
            };


        case 'SET_RECEPTION_UPDATER':
            return {
                ...state,
                receptionUpdater: state.receptionUpdater + 1
            };


        default:
            return state;
    }
}



export default applicationReducer;

