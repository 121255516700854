import { combineReducers } from 'redux';
import App from '../redux/app/reducer';
import ThemeSwitcher from '../redux/themeSwitcher/reducer';
import LanguageSwitcher from '../redux/languageSwitcher/reducer';
import applicationReducer from '../redux/applicationData/reducer';

export default combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  applicationReducer,
});
